import { joiResolver } from '@hookform/resolvers/joi';
import { Box, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import Joi from 'joi';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import API from '../../../../../global/config/api';
import {
  copyToClipboard,
  exportToExcel,
  getAuditor,
  getCompanyId,
  getFilters,
  getPerson,
  isAuditor,
  isSubmitter
} from '../../../../../global/helpers/actions';
import {
  useCodeList,
  useCompany,
  useFormChanges,
  useLoadData,
  useServerCall
} from '../../../../../global/helpers/hooks';
import { QueryGuard, SaveGuard } from '../../../../../global/helpers/query';
import { isEmailValid, isPhoneNumberValid } from '../../../../../global/helpers/validators';
import { IEnergeticAuditHeaderForm } from '../../../../../global/interfaces/forms/energeticAudit/energeticAuditHeader.form.interface';
import { IEnergeticAuditHeaderRequest } from '../../../../../global/interfaces/requests/energeticAudits/energeticAuditHeader.request.interface';
import {
  ICodeListObject,
  ICodeListResponse
} from '../../../../../global/interfaces/responses/codeList.response.interface';
import { IEnergeticAuditResponse } from '../../../../../global/interfaces/responses/energeticAudit.response.interface';
import UIStore from '../../../../../global/stores/UIStore';
import { TEnergeticAuditStatus } from '../../../../../global/types/types';
import { XsBreadcrumbs, XsButton, XsLoading } from '../../../../../global/ui';
import XsLeaveConfirmationDialog from '../../../../../global/ui/confirmationDialog/XsLeaveConfirmationDialog';
import EnergeticAuditStore from '../../EnergeticAuditStore';
import EnergeticAuditHelp from '../EnergeticAuditHelp';
import EnergeticAuditHeaderForm from './EnergeticAuditHeaderForm';
import LoginStore from '../../../login/LoginStore';
import { IExportToExcel } from '../../../../../global/interfaces/requests/exportToExcel.request.interface';

type TEnergeticAuditHeader = {
  energeticAuditID: [string, Dispatch<SetStateAction<string>>];
  onBack: () => void;
};

export type TSaveState = 'next' | 'stay' | 'back';

export enum EABreadcrumb {
  HEADER_LIST = 'EAHeaderList',
  HEADER_DETAIL = 'EAHeaderDetail',
  ENERGETIC_OBJECTS = 'EAEnergeticObject',
  FEC = 'EAFinalEnergyConsumption',
  PROVISIONS = 'EAProvisions',
  CARRIERS = 'EACarriers',
  SUMMARY = 'EASummary'
}

const schema = Joi.object<IEnergeticAuditHeaderForm>({
  autor: Joi.string(),
  preparationDate: Joi.date().max('now').min(new Date(2000, 0, 1)).required(),
  submitionReason: Joi.object().required(),
  submitter: Joi.string().required().allow(null),
  street: Joi.string().allow('').allow(null),
  zip: Joi.string().allow('').allow(null),
  houseNumber: Joi.string().allow('').allow(null),
  city: Joi.string().allow('').allow(null),
  sector: Joi.object().required(),
  skNace: Joi.object().required().allow(null),
  auditorCertificate: Joi.string().allow(''),
  auditorFirstName: Joi.string().required(),
  auditorLastName: Joi.string().required(),
  auditorEmail: Joi.string().regex(isEmailValid()).message('email_message').allow(''),
  auditorEmail2: Joi.string().regex(isEmailValid()).message('email_message').allow(''),
  auditorPhone: Joi.string().regex(isPhoneNumberValid()).allow('').message('phone_message'),
  note: Joi.string().max(200).allow(''),
  submitterName: Joi.string().allow(''),
  submitterIco: Joi.string().allow(''),
  submitterCountryId: Joi.string().allow('')
});

type TSuccessSaveResponse = {
  data: {
    _id: string;
    identifier: string;
    subject_company: {
      _id: string;
      identifier: string;
    };
  };
};

function EnergeticAuditHeaderContainer({ energeticAuditID, onBack }: TEnergeticAuditHeader) {
  const { t } = useTranslation();

  const next = useRef<TSaveState>('stay');

  const [energeticAuditId, setEnergeticAuditID] = energeticAuditID;
  const addNewEA = useRef<boolean>(false); //flag, aby sa pri pridavani noveho, neodpaloval useLoadData

  const { data: sectors } = useCodeList<ICodeListResponse>('Entity.CompanySector');
  const { data: skNace } = useCodeList<ICodeListResponse>('Entity.SK.NACE');
  const { data: reasons } = useCodeList<ICodeListResponse>('EnergeticAudit.SubmissionReason');

  const initData = useRef<IEnergeticAuditHeaderForm | null>(null);

  const saveEnergeticAudit = useServerCall<IEnergeticAuditHeaderRequest, any>({
    key: 'saveEnergeticAuditHeader',
    mode: 'control',
    request: {
      url: API.EAAudit(),
      method: 'PUT'
    },
    onSuccess: (res: TSuccessSaveResponse) => {
      UIStore.setNotificationMessage(t('energeticAudit.audit_header.save_success'), 'success');

      const formValues: IEnergeticAuditHeaderForm = form.getValues();

      addNewEA.current = true;
      setEnergeticAuditID(res.data._id);
      setValue('submitter', res.data.subject_company._id);

      const submitter_name = formValues.submitterName
        ? formValues.submitterName
        : formValues.companyName
        ? formValues.companyName
        : '';

      const submitter_ico = formValues.submitterIco ? formValues.submitterIco : formValues.ico ? formValues.ico : '';

      EnergeticAuditStore.energeticAuditBaseInfo = {
        id: res.data.subject_company._id,
        identifier: res.data.subject_company.identifier,
        auditor_name: `${formValues.auditorFirstName} ${formValues.auditorLastName}`,
        submitter_name: submitter_name,
        submitter_ico: submitter_ico,
        preparation_date: formValues.preparationDate
      };

      UIStore.changeLastBreadcrumbsStep(
        <span>
          {t('energeticAudit.audit_header.breadcrumb')}:{' '}
          <span className='bold'>{res.data.subject_company.identifier}</span>
        </span>
      );

      if (next.current === 'next') {
        EnergeticAuditStore.setEaTabScreen('finalEnergyConsumption');
      }
      if (next.current === 'stay') {
        initData.current = form.getValues();
      }
    },
    onError: (err) => {
      UIStore.setNotificationMessage(err, 'error');
    },
    queryOptions: { refetchOnWindowFocus: false, staleTime: 500 }
  });

  const form = useForm<IEnergeticAuditHeaderForm>({
    resolver: joiResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const { handleSubmit, setValue } = form;
  const onSubmit = (formVals: IEnergeticAuditHeaderForm) => {
    let req: IEnergeticAuditHeaderRequest = {
      _id: energeticAuditId ? energeticAuditId : null,
      preparation_date: format(formVals.preparationDate, 'yyyy-MM-dd'),
      submission_reason_id: formVals.submitionReason?._id,
      auditor: {
        email: formVals.auditorEmail,
        email2: formVals.auditorEmail2,
        phone_number: formVals.auditorPhone,
        certificate: formVals.auditorCertificate,
        first_name: formVals.auditorFirstName,
        last_name: formVals.auditorLastName
      },
      submitting_person: {
        _id: LoginStore.userInfo?.person._id ?? ''
      }
    };

    if (LoginStore.currentCompany && LoginStore.currentCompany?.company_id) {
      req.submitting_company = {
        _id: LoginStore.currentCompany?.company_id
      };
    }

    if (formVals.submitter) {
      req.subject_company = {
        _id: formVals.submitter,
        classification_id: formVals.skNace?._id,
        sector_id: formVals.sector?._id
      };
    } else {
      req.subject_company = {
        classification_id: formVals.skNace?._id,
        sector_id: formVals.sector?._id,
        country_id: formVals.submitterCountryId,
        identifier: formVals.submitterIco
      };
    }

    saveEnergeticAudit.run({
      url: API.EAAudit(),
      data: { ...req }
    });
  };

  const initBreadcrumbs = (identifier?: string) => {
    UIStore.setBreadcrumbs([
      {
        ID: EABreadcrumb.HEADER_LIST,
        label: t('energeticAudit.audit_list.list'),
        action: () => onBack()
      },
      {
        ID: EABreadcrumb.HEADER_DETAIL,
        label: (
          <span>
            {t('energeticAudit.audit_header.breadcrumb')}: <span className='bold'>{identifier}</span>
          </span>
        ),
        action: () => EnergeticAuditStore.setEaTabScreen('header')
      }
    ]);
  };

  const formLoadData = useLoadData<void, IEnergeticAuditResponse, IEnergeticAuditHeaderForm>({
    disabled: energeticAuditId && !addNewEA.current ? false : true,
    key: ['energeticAuditHeader', energeticAuditId],
    request: {
      url: API.EAAudit(energeticAuditId, 'CDL2'),
      method: 'GET'
    },
    queryOptions: { refetchOnWindowFocus: false, staleTime: 5 * 1000 },
    transform: (data) => {
      let transJSON: IEnergeticAuditHeaderForm = {
        autor: `${data.submitting_person._id}||${data?.submitting_company?._id ?? 'null'}`,
        submitter: data.subject_company._id,
        auditorFirstName: data.auditor.first_name,
        auditorLastName: data.auditor.last_name,
        auditorEmail: data.auditor.email,
        auditorEmail2: data.auditor.email2,
        auditorPhone: data.auditor.phone_number,
        auditorCertificate: data.auditor.certificate,
        houseNumber: data.subject_company.house_number,
        street: data.subject_company.street_plain,
        zip: data.subject_company.zip_plain,
        preparationDate: new Date(data.preparation_date),
        submitterName: data.subject_company.name,
        sector: null,
        skNace: null,
        submitionReason: null,
        city: data.subject_company.city_id
      };

      if (sectors) {
        const sector: ICodeListObject | undefined = sectors.rows.find(
          (x: ICodeListObject) => x._id === data.subject_company.sector_id
        );

        if (sector) {
          transJSON['sector'] = sector;
        }
      }

      if (skNace) {
        const nace: ICodeListObject | undefined = skNace.rows.find(
          (x: ICodeListObject) => x._id === data.subject_company.classification_id
        );

        if (nace) {
          transJSON['skNace'] = nace;
        }
      }

      if (reasons) {
        const reason: ICodeListObject | undefined = reasons.rows.find(
          (x: ICodeListObject) => x._id === data.submission_reason_id
        );

        if (reason) {
          transJSON['submitionReason'] = reason;
        }
      }

      initBreadcrumbs(data.identifier);

      EnergeticAuditStore.energeticAuditBaseInfo = {
        id: data._id,
        identifier: data.identifier,
        auditor_name: `${data.auditor.first_name} ${data.auditor.last_name}`,
        submitter_name: data.subject_company.name,
        submitter_ico: data.subject_company.identifier,
        preparation_date: new Date(data.preparation_date)
      };

      initData.current = transJSON;

      EnergeticAuditStore.setEAStatus(data.status_id as TEnergeticAuditStatus);

      return transJSON;
    },
    form
  });

  useCompany({
    ...formLoadData,
    companyIdSelector: (data) => (isAuditor() ? data?.submitting_company?._id : data.subject_company?._id)
  });

  const templateExport = () => {
    let filters = getFilters([`audit_id=${energeticAuditId}`]);

    let req: IExportToExcel = {
      filters: filters.filters
    };

    exportToExcel(req, undefined, undefined, API.EAExportTemplate());
  };

  const getChanges = useFormChanges<IEnergeticAuditHeaderForm>({
    exceptions: isAuditor() ? [] : ['street', 'houseNumber', 'city', 'zip', 'submitterIco', 'submitterName'],
    form,
    initDataRef: initData
  });

  useEffect(() => {
    //ak sa nejedna o update, tak predpln auditora/submitter
    if (!energeticAuditId) {
      if (isAuditor()) {
        const auditor = getAuditor();
        const person = getPerson();

        if (auditor?._id) {
          setValue('auditorFirstName', person?.first_name ?? '');
          setValue('auditorLastName', person?.last_name ?? '');
          setValue('auditorCertificate', auditor.certificate ?? '');
          setValue('auditorEmail', auditor.email ?? '');
          setValue('auditorEmail2', auditor.email2 ?? '');
          setValue('auditorPhone', auditor.phone_number ?? '');
        }
      }

      if (isSubmitter() && getCompanyId()) {
        setValue('submitter', getCompanyId() ?? '');

        const reason: ICodeListObject | undefined = reasons?.rows.find((x: ICodeListObject) => x._id === '1');

        setValue('submitionReason', reason ?? null);
      }

      if (initData.current === null) {
        initData.current = form.getValues();
      }
      initBreadcrumbs(EnergeticAuditStore.energeticAuditBaseInfo?.identifier);
    }
  }, [energeticAuditId, setValue]);

  const onFormError = (formErrors: any, e: any) => {
    UIStore.setNotificationMessage(t('global.checkReqiredFields'), 'error');
  };

  const isNewEA: boolean = energeticAuditId && !addNewEA.current ? false : true;

  return (
    <Box p={3}>
      <QueryGuard isNew={isNewEA} {...formLoadData} LoadingElement={<XsLoading />} ErrorElement={<span></span>}>
        <FormProvider {...form}>
          <SaveGuard isPending={saveEnergeticAudit.isPending} LoadingElement={<XsLoading overlay={true} />}>
            <>
              <Grid container justifyContent='space-between' alignItems='baseline'>
                <Grid item>
                  <XsBreadcrumbs changes={() => getChanges(initData.current)} />
                </Grid>
                <Grid item>
                  <Typography variant='body2'>
                    <i
                      className='fas fa-link blueLight'
                      style={{ paddingRight: 10, cursor: 'pointer' }}
                      title={t('global.link_step')}
                      onClick={async () => {
                        const isCopied = await copyToClipboard(window.location.href);
                        if (isCopied) {
                          UIStore.setNotificationMessage(t('global.copy_to_clipboard_success'), 'success');
                        } else {
                          UIStore.setNotificationMessage(t('global.copy_to_clipboard_failed'), 'warning');
                        }
                      }}
                    ></i>
                    {t('energeticAudit.audit_header.title')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container py={2}>
                <EnergeticAuditHeaderForm energeticAuditId={energeticAuditId} newEA={addNewEA.current} />
                <EnergeticAuditHelp
                  help={[t('energeticAudit.audit_header.help_1'), t('energeticAudit.audit_header.help_2')]}
                />
              </Grid>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  <XsButton
                    startIcon={<i className='fas fa-arrow-left fa-lg'></i>}
                    variant='contained'
                    label={'energeticAudit.audit_header.back'}
                    onClick={() => {
                      const changes = getChanges(initData.current);
                      if (!(EnergeticAuditStore.eaStatus !== 'pre') && changes?.length) {
                        UIStore.openConfirmationDialog('leaveConfirmation', { discriminator: 'list' });
                      } else {
                        onBack();
                      }
                    }}
                  />
                </Grid>
                <Grid item container xs justifyContent='flex-end' spacing={2}>
                  <Grid item>
                    <XsButton
                      startIcon={<i className='fas fa-save fa-lg'></i>}
                      variant='outlined'
                      label={'energeticAudit.audit_header.export_template'}
                      disabled={!(EnergeticAuditStore.eaStatus === 'pre' && energeticAuditId)}
                      onClick={() => templateExport()}
                    />
                  </Grid>
                  <Grid item>
                    <XsButton
                      startIcon={<i className='fas fa-save fa-lg'></i>}
                      variant='outlined'
                      disabled={EnergeticAuditStore.eaStatus !== 'pre'}
                      label={'global.save'}
                      onClick={handleSubmit<IEnergeticAuditHeaderForm>(onSubmit, onFormError)}
                    />
                  </Grid>
                  <Grid item>
                    <XsButton
                      endIcon={<i className='fas fa-arrow-right fa-lg'></i>}
                      variant='contained'
                      label={'energeticAudit.audit_header.next_to_KES'}
                      onClick={() => {
                        //update existujuceho
                        if (energeticAuditId) {
                          const changes = getChanges(initData.current);
                          if (!(!(EnergeticAuditStore.eaStatus !== 'pre') && changes?.length)) {
                            EnergeticAuditStore.setEaTabScreen('finalEnergyConsumption');
                          } else {
                            UIStore.openConfirmationDialog('leaveConfirmation');
                          }
                        } else {
                          next.current = 'next';
                          handleSubmit<IEnergeticAuditHeaderForm>(onSubmit, (formErrors, e) => {
                            onFormError(formErrors, e);
                            next.current = 'stay';
                          })();
                          // handleSubmit<IEnergeticAuditHeaderForm>(onSubmit, (e) => onError(e))();
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <XsLeaveConfirmationDialog
                onConfirmation={() => {
                  if (UIStore.confParams?.discriminator === 'list') {
                    onBack();
                  } else if (UIStore.confParams?.discriminator === 'breadcrumb') {
                    UIStore.goToBreadcrumbsStep(UIStore.confParams?.breadcrumbStep);
                  } else if (
                    UIStore.confParams?.discriminator === 'formChanges' &&
                    UIStore.confParams?.discriminatorAction
                  ) {
                    UIStore.confParams.discriminatorAction();
                  } else {
                    EnergeticAuditStore.setEaTabScreen('finalEnergyConsumption');
                  }
                }}
              />
            </>
          </SaveGuard>
        </FormProvider>
      </QueryGuard>
    </Box>
  );
}

export default observer(EnergeticAuditHeaderContainer);
